import { GoogleIAPData } from "./models";
import { syncExternalSubscription } from "./api";
import { storageSaveUser } from "./utils";

if (window.Android) {
    window.setSubscriptionData = async (purchaseInfo: GoogleIAPData, ignoreResponse?: boolean) => {
        // show loading if user initiated sub
        window.dispatchEvent(
            new CustomEvent("externalSubscriptionSync", {
                detail: { syncing: true }
            })
        );

        console.log(`purchaseInfo ${purchaseInfo}`);
        console.log(`typeof purchaseInfo ${typeof purchaseInfo}`);

        try {
            // amazon
            // data = JSON.parse(data);

            // google
            // TODO: types
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: for later
            purchaseInfo = JSON.parse(purchaseInfo);
        } catch (e) {
            console.log(e);
        }

        // google, get token and sub id
        const { purchaseToken, subscriptionId } = purchaseInfo;
        let hasError = false;
        try {
            const res = await syncExternalSubscription({
                // google
                purchaseToken,
                subscriptionId
            });

            storageSaveUser({ ...res, userId: purchaseToken });

            console.log(`SAVE RES ${JSON.stringify(res)}`);
        } catch (e) {
            console.log(e);
            hasError = !ignoreResponse;
        }

        window.dispatchEvent(
            new CustomEvent("externalSubscriptionSync", {
                detail: { syncing: false, error: hasError }
            })
        );
    };

    window.handleAppBackPressed = () => {
        window.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 8 }));
    };
}
