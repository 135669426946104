import { Lightning, Router, Storage } from "@lightningjs/sdk";
import { Button } from "../Button/Button";
import { getUserId, toggleFavorite } from "../../lib/api";
import { MovieModel } from "../../lib/models";
import { isExternalPlatform, isSubscriptionActive, STORAGE_KEYS } from "../../lib/utils";

interface MovieActionsTemplateSpec extends Lightning.Component.TemplateSpec {
    data: MovieModel;
    Buttons: {
        Watch: typeof Button;
        Favorite: typeof Button;
    };
}

export class MovieActions
    extends Lightning.Component<MovieActionsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MovieActionsTemplateSpec>
{
    _boundEventHandler?: any;

    _movieId: number | undefined;
    _isFavorite = false;

    static override _template(): Lightning.Component.Template<MovieActionsTemplateSpec> {
        return {
            collision: true,
            Buttons: {
                flexItem: { marginTop: 56 },
                flex: {
                    alignItems: "center"
                },
                Watch: {
                    flexItem: { marginRight: 20 },
                    w: 188,
                    type: Button,
                    hasActive: false,
                    label: "watch",
                    icon: "play",
                    iconWidth: 20,
                    signals: {
                        handleClick: "_handleWatchState"
                    }
                },
                Favorite: {
                    w: 56,
                    type: Button,
                    hasActive: false,
                    signals: {
                        handleClick: "_handleFavoriteState"
                    }
                }
            }
        };
    }

    readonly Buttons = this.getByRef("Buttons")!;
    readonly Watch = this.Buttons.getByRef("Watch")!;
    readonly Favorite = this.Buttons.getByRef("Favorite")!;

    override _setup() {
        this._boundEventHandler = this._update.bind(this);
    }

    override _active() {
        this.application.on("updateSelectedMovie", this._boundEventHandler);
    }

    override _inactive() {
        this.application.off("updateSelectedMovie", this._boundEventHandler);
    }

    override _init() {
        this._setState("WatchState");
    }

    set data(value: MovieModel) {
        this._update(value);
    }

    _update(movie: MovieModel) {
        this._setState("WatchState");

        this._movieId = movie.movieId;
        this._isFavorite = movie.isFavorite;

        this._updateButtons();
    }

    _updateButtons() {
        this.Favorite.patch({
            icon: this._isFavorite ? "favorite-filled" : "favorite-outline"
        });
    }

    _handleWatchState() {
        this._setState("WatchState");

        this._handleWatchState();
    }

    _handleFavoriteState() {
        this._setState("FavoriteState");

        this._handleFavoriteState();
    }

    static override _states() {
        return [
            class WatchState extends this {
                override _getFocused() {
                    return this.Watch;
                }

                override _handleRight() {
                    this._setState("FavoriteState");
                }

                override _handleEnter() {
                    this._enter();
                }

                override _handleWatchState() {
                    this._enter();
                }

                _navigateToPlayback() {
                    Router.navigate(`playback/${this._movieId}`, {
                        keepAlive: Router.getActiveHash() === "search"
                    });
                }

                async _enter() {
                    if (isSubscriptionActive()) {
                        this._navigateToPlayback();
                    } else {
                        if (isExternalPlatform()) {
                            // try to sync first
                            try {
                                console.log("TRY SYNC");
                                await window.setSubscriptionData(window.Android!.getSubscriptionData(), true);
                            } catch (e) {
                                console.log("SYC ERR");
                                console.log(e);
                            }

                            console.log(`is sub active ${isSubscriptionActive()}`);

                            if (!isSubscriptionActive()) {
                                console.log("trigger CREATE SUB");
                                window.Android!.createSubscription();
                            } else {
                                this._navigateToPlayback();
                            }
                        } else {
                            console.log("!!Storage.get(STORAGE_KEYS.user)", !!Storage.get(STORAGE_KEYS.user));
                            if (!!Storage.get(STORAGE_KEYS.user)) {
                                Router.focusWidget("Subscription");
                            } else {
                                Router.focusWidget("Auth");
                            }
                        }
                    }
                }
            },
            class FavoriteState extends this {
                override _getFocused() {
                    return this.Favorite;
                }

                override _handleLeft() {
                    this._setState("WatchState");
                }

                override _handleEnter() {
                    this._enter();
                }

                override _handleFavoriteState() {
                    this._enter();
                }

                _enter() {
                    if (this._movieId && getUserId()) {
                        (async () => {
                            try {
                                await toggleFavorite(this._movieId!);
                                this._isFavorite = !this._isFavorite;

                                this._updateButtons();
                            } catch (e) {
                                console.log("e", e);
                            }
                        })();
                    } else {
                        Router.focusWidget("Auth");
                    }
                }
            }
        ];
    }
}
